import React from "react";
import ContentBlock from "../ContentBlock";

export default function Home() {
  return (
    <div>
      <ContentBlock headLine={"Om Claria"} breadText={["Claria AS er grunnlagt av Veslemøy Linde, utdannet som tannlege, spesialkompetanse innen lystgassanalgesi og sedasjon og deretter spesialist i endodonti, alt ved Universitetet i Oslo. Videreutdanning innen hygiene og smittevern er fra Universitetet i Sørøst-Norge. Utdanning innen søvnapné fra Sykehuset Innlandet HF og Haukeland Universitetssykehus HF /Helse Bergen. Linde har erfaring fra offentlige og private virksomheter, fylkeskommuner, sykehus og kompetansesenter.", "", "Vi tar imot henvisninger fra tannleger og tannpleiere i både offentlig og privat virksomhet innen feltet endodonti, det vil si forebyggelse og behandling av kjeveinfeksjoner.", "Vi driver rådgivingstjeneste og opplæring innen smittevern og infeksjonskontroll.", "Vi tilbyr skinnebehandling ved søvnapné diagnose. Diagnostisering og monitorering i søvnlaboratorium skjer via fastlege og ØNH-avdeling."]} site={"home"}/>
    </div>
  );
}
