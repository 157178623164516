import React from 'react'
import ContentBlock from "../ContentBlock";

export default function Smittevern() {

  let liArray = []

  let unorderedlist = ["Prosjektering av nybygg eller rehabilitering av klinikk mht hygiene og infeksjonskontroll",
    "Risikovurdering og tiltak",
    "Forskrifter, regelverk, infeksjonskontrollprogram, prosedyrehåndtering, nasjonale standarder",
    "Hjelp i forhold til medier og markedsføring med hensyn til korrekte prosedyrer i tekst og bilder",
    "Håndtering av arbeidsklær og gjeldende regelverk"
    ]


    unorderedlist.forEach((el, i) => {
      liArray.push(<li className='mt-3 mb-3' key={i}>{el}</li>)
    })

  return (
    <div>
      <ContentBlock headLine={"Smittevernfaglig rådgivingstjeneste for tannhelsetjenesten"} breadText={["Vi tilbyr rådgivingstjeneste overfor privat og offentlig tannhelsetjeneste innen smittevern:"]} uList={liArray} site={"smittevern"} />
    </div>
  )
}
