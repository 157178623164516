import React from 'react';
import Dropdown from './Dropdown';

export default function Navbar() {

  return (
    <header className='mt-[18px]' style={{width: "100%", backgroundColor: "#ffffff"}}>
      <div id="navbar">
        <div>
          <a tabIndex={-1} href="/">
          <img
            src="/assets/logo_claria_1.webp"
            alt=""
            style={{width: "150px", objectFit: "scale-down"}}
          />
          </a>
        </div>
        <Dropdown />
      </div>
    </header>
  )
}
