import React from 'react'
import ContentBlock from "../ContentBlock";

export default function Skinnebehandling() {

  return (
    <div>
      <ContentBlock headLine={"Skinnebehandling mot søvnapné"} breadText={["Under søvn kan man ha et uregelmessig pustemønster, men dersom man har pustestopp og det påvises for lite oksygen til kroppen under søvn, kan det få flere uheldige følger.", "I tillegg til redusert søvnkvalitet med sterk tretthet på dagtid og konsentrasjonsvansker, kan det gi symptomer som høyt blodtrykk og økt sannsynlighet for hjerte/ kar sykdommer.", "Noen sykehus har søvnpoliklinikker, og du kan få hjelp til å finne ut om du har søvnapné. Der vil man kunne ta en søvnregistrering/ polygrafi for å avdekke eventuell diagnose.", "Hjelp av en CPAP maskin er førstevalget mot tilstanden. Dersom du av ulike årsaker ikke kan benytte dette, er det mulig å fremstille en skinne for de aller fleste. Dersom du har rettigheter for å få skinnen som behandlingshjelpemiddel, utføres det i sykehus.", "I motsatt fall kan det fremstilles hos oss."]} site={"skinnebehandling"}/>
    </div>
  )
}
