import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home.js";
import Skinnebehandling from "./pages/Skinnebehandling";
import Smittevern from "./pages/Smittevern";
import Spesialistbehandling from "./pages/Spesialistbehandling";
import Navbar from "./Navbar.js";
import Footer from "./Footer.js";

function App() {
  return (
    <div>
      <a tabIndex={1} href="#skip-to-content" className="absolute left-0 top-0 bg-blue-500 text-white py-2 px-4 z-50 transform -translate-y-full focus:translate-y-0 transition">
        Skip to main content
      </a>
      <Navbar />
      <div id="skip-to-content" tabIndex={-1}>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/skinnebehandling" element={<Skinnebehandling />}></Route>
        <Route path="/smittevern" element={<Smittevern />}></Route>
        <Route
          path="/spesialistbehandling"
          element={<Spesialistbehandling />}
        ></Route>
        <Route path="*" element={<Home />}></Route>
      </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
