import React from 'react'
import ContentBlock from "../ContentBlock";


export default function Spesialistbehandling() {

  let liArray = []

  let unorderedlist = ["Diagnostikk og behandling av sykdommer som kommer fra tannpulpa og området rundt denne",
    "Smerteutredning",
    "Særlig vanskelig tilgjengelighet til rotkanal, som kronetenner og obliterasjoner",
    "Resorpsjoner",
    "Reparasjon av iaterogene defekter som perforasjoner",
    "Atypisk rotmorfologi",
    "Fjerning av frakturerte instrumenter i rotkanalsystemet",
    "Kompliserte traumekasus inkl rotåpne tenner",
    "Endo-periolesjoner",
    "Persisterende infeksjoner",
    "Differensialdiagnostikk",
    "Bakteriologisk prøvetaking"
    ]

    unorderedlist.forEach((el, i) => {
      liArray.push(<li className='mt-3 mb-3' key={i}>{el}</li>)
    })

  return (
    <div>
      <ContentBlock headLine={"Forebyggelse og behandling av kjeveinfeksjon"} breadText={["Dersom du er tannlege eller tannpleier kan du henvise digitalt eller per post.", "Vi tar imot pasienter fra både fra offentlig og privat tannhelsetjeneste, for spesialistbehandling innen endodonti/ rotbehandling.", "Vi forebygger og behandler kjeveinfeksjon ved ortograd eller retrograd prosedyre; det vil si rotfylling internt i tann eller kirurgisk behandling på rotspiss:"]} uList={liArray} site={"spesialistbehandling"}/>
    </div>
  )
}
