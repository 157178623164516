import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

export default function ContentBlock({
  headLine,
  breadText,
  uList,
  site,
}) {

  let carNumber;

  let carouselImg1;
  let carouselImg2;
  let carouselImg3;
  let carouselImg4;
  let carouselImg5;
  let carouselImg6;
  let carouselImg7;
  let carouselImg8;
  let carouselImg9;

  let imgArray = []
  let slidesArray = [];
 
  let pArray = [];

  if (site === "skinnebehandling") {
    carNumber = 4;
    carouselImg1 = "/assets/sovnapne_carousel/sovnapne_1.webp";
    carouselImg2 = "/assets/sovnapne_carousel/sovnapne_2.webp";
    carouselImg3 = "/assets/sovnapne_carousel/sovnapne_3.webp";
    carouselImg4 = "/assets/sovnapne_carousel/sovnapne_4.webp";
    imgArray = [carouselImg1, carouselImg2, carouselImg3, carouselImg4]

    if (imgArray) {
      imgArray.forEach((img, index) => {
        if(img) {
          slidesArray.push(<Slide tabIndex={-1} className="carousel-img" index={index}>
            <Image src={img} />
          </Slide>)
        }
      });
    }
  }

  if (site === "home") {
    carNumber = 9;
    carouselImg1 = "/assets/main_carousel/main_1.webp";
    carouselImg2 = "/assets/main_carousel/main_2.webp";
    carouselImg3 = "/assets/main_carousel/main_3.webp";
    carouselImg4 = "/assets/main_carousel/main_4.webp";
    carouselImg5 = "/assets/main_carousel/main_5.webp";
    carouselImg6 = "/assets/main_carousel/main_6.webp";
    carouselImg7 = "/assets/main_carousel/main_7.webp";
    carouselImg8 = "/assets/main_carousel/main_8.webp";
    carouselImg9 = "/assets/main_carousel/main_9.webp";
    imgArray = [carouselImg1, carouselImg2, carouselImg3, carouselImg4, carouselImg5, carouselImg6, carouselImg7, carouselImg8, carouselImg9]

    if (imgArray) {
      imgArray.forEach((img, index) => {
        if(img) {
          slidesArray.push(<Slide tabIndex={-1} className="carousel-img" index={index}>
            <Image src={img} />
          </Slide>)
        }
      });
    }
  }

  if (site === "smittevern") {
    carNumber = 4;
    carouselImg1 = "/assets/smittevern_carousel/smittevern_1.webp";
    carouselImg2 = "/assets/smittevern_carousel/smittevern_2.webp";
    carouselImg3 = "/assets/smittevern_carousel/smittevern_3.webp";
    carouselImg4 = "/assets/smittevern_carousel/smittevern_4.webp";
    imgArray = [carouselImg1, carouselImg2, carouselImg3, carouselImg4]

    if (imgArray) {
      imgArray.forEach((img, index) => {
        if(img) {
          slidesArray.push(<Slide tabIndex={-1} className="carousel-img" index={index}>
            <Image src={img} />
          </Slide>)
        }
      });
    }    
  }

  if (site === "spesialistbehandling") {
    carNumber = 3;
    carouselImg1 = "/assets/endodonti_carousel/endodonti_1.webp";
    carouselImg2 = "/assets/endodonti_carousel/endodonti_2.webp";
    carouselImg3 = "/assets/endodonti_carousel/endodonti_3.webp";
    imgArray = [carouselImg1, carouselImg2, carouselImg3]

    if (imgArray) {
      imgArray.forEach((img, index) => {
        if(img) {
          slidesArray.push(<Slide tabIndex={-1} className="carousel-img" index={index}>
            <Image src={img} />
          </Slide>)
        }
      });
    }
  }

  if (breadText) {
    breadText.forEach((el, i) => {
      pArray.push(<p key={i}>{el}</p>);
      pArray.push(<br />);
    });
  }

  return (
    <div className="w-[100%] flex flex-col items-center">
      <div
        id="home"
        className="main_content w-[100%] flex flex-col justify-center items-center m-0 rounded-[10px] md:w-[90%] text-center lg:w-[85%]"
      >
        <div className="w-[100%] bg-[#023E8A] mb-8 pb-[18px] md:rounded">
          <br />
        </div>
        <div className="flex flex-col items-center justify-between w-[100%]">
          <div  className="aboutSection w-[100%] items-center ml-[35px] mr-[35px] flex flex-col items-center">
            <h2 tabIndex={0} id="about" className="mb-8 w-[95%] text-[#0077B6]">
              {headLine}
            </h2>
            <div className="w-[100%] flex flex-col items-center md:flex-row justify-between">
              <div className="w-[100%] mb-[40px] pb-[25px] pt-[25px] bg-[#0077B6] md:w-[45%] md:rounded-lg">
                <CarouselProvider
                  naturalSlideWidth={16}
                  naturalSlideHeight={9}
                  totalSlides={carNumber ? carNumber : 3}
                  hasMasterSpinner={true}
                  isPlaying={true}
                  infinite={true}
                  interval={5000}
                >
                  <div className="relative">
                    <ButtonBack tabIndex={-1} className="absolute text-white z-50 inset-y-0 left-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 19.5 8.25 12l7.5-7.5"
                        />
                      </svg>
                    </ButtonBack>
                    <ButtonNext tabIndex={-1} className="absolute text-white z-50 inset-y-0 right-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m8.25 4.5 7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    </ButtonNext>
                    <Slider tabIndex={-1}>
                      {slidesArray}
                    </Slider>
                  </div>
                </CarouselProvider>
              </div>

              <div tabIndex={0} className="w-[80%] mb-[12px] flex flex-col justify-center items-center md:w-[45%]">
                {pArray}
              </div>
            </div>
            <div className="w-[100%] flex flex-row justify-center">
              <ul tabIndex={0} className="w-[70%] mt-[-12px] mb-[36px] list-disc text-left">
                {uList}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
